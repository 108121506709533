import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { Auth } from 'aws-amplify';
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(public auth: AuthService, public router: Router) { }

  async canActivate() {
    try {
      let user = await Auth.currentAuthenticatedUser({
        bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const currentSession = await Auth.currentSession();
      if (!user || !currentSession.isValid){
        Auth.signOut({ global: true })
          .then(data => { })
          .catch(err => console.log(err));
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['login']);
        return false;
      }
      return true;
    } catch (err) {
     
      console.log(err);
      Auth.signOut({ global: true })
        .then(data => { })
        .catch(err => console.log(err));
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(['login']);
    }
    return false;
  }

}