import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { Subject } from 'rxjs';
import { AuthService } from './service/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'lmsadmin';

  constructor(private router: Router, private authService: AuthService) {

  }

  @HostListener('mousemove') mouseMove() {
    
    this.authService.clearTimeout();
    this.authService.setTimeout();
  }

  @HostListener('document:wheel') scroll() {
 
    this.authService.clearTimeout();
    this.authService.setTimeout();
  }
  

}
