import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatButtonModule, MatDialogModule, MatListModule, MatProgressBarModule, MatRadioModule, MatIconModule } from '@angular/material';
import { DialogComponent } from './dialog/dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { FlexLayoutModule } from '@angular/flex-layout';
import { UploadService } from '../service/upload.service';
import { HttpClientModule } from '@angular/common/http';
import { UploadComponent } from './upload.component';
//FlexLayoutModule, 
@NgModule({
  imports: [CommonModule, MatButtonModule, MatDialogModule,MatIconModule, MatListModule, HttpClientModule, BrowserAnimationsModule, MatProgressBarModule,MatRadioModule,FormsModule],
  declarations: [DialogComponent, UploadComponent],
  exports: [DialogComponent, UploadComponent],
  entryComponents: [DialogComponent],
  providers: [UploadService]
})
export class UploadModule {}