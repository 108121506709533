import { Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-qa-links',
  templateUrl: './qa-links.component.html',
  styleUrls: ['./qa-links.component.css']
})
export class QaLinksComponent implements OnInit {

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.authService.setTimeout();
  }

}
