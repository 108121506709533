import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogData {
  animal: string;
  name: string;
}
@Component({
  selector: 'app-push-confirm',
  templateUrl: './push-confirm.component.html',
  styleUrls: ['./push-confirm.component.css']
})
export class PushConfirmComponent {

  constructor(public dialogRef: MatDialogRef<PushConfirmComponent>) { }
 
  yesClick(): void {
    this.dialogRef.close('yes');
  }
  cancelClick(): void {
    this.dialogRef.close('no');
  }

}
