import { Injectable } from '@angular/core';
export interface Code {
  text: string;
  code: string;
}

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  constructor() { }
  getAuthorinTools(): Code[] {
    return [{ text: 'Storyline', code: 'Storyline' }];
  }
  getCountryCodes(): Code[] {
    return [
      { text: 'Afghanistan', code: 'AF' },
      { text: 'Albania', code: 'AL' },
      { text: 'Algeria', code: 'DZ' },
      { text: 'American Samoa', code: 'AS' },
      { text: 'Andorra', code: 'AD' },
      { text: 'Angola', code: 'AO' },
      { text: 'Anguilla', code: 'AI' },
      { text: 'Antarctica', code: 'AQ' },
      { text: 'Antigua and Barbuda', code: 'AG' },
      { text: 'Argentina', code: 'AR' },
      { text: 'Armenia', code: 'AM' },
      { text: 'Aruba', code: 'AW' },
      { text: 'Australia', code: 'AU' },
      { text: 'Austria', code: 'AT' },
      { text: 'Azerbaijan', code: 'AZ' },
      { text: 'Bahamas', code: 'BS' },
      { text: 'Bahrain', code: 'BH' },
      { text: 'Bangladesh', code: 'BD' },
      { text: 'Barbados', code: 'BB' },
      { text: 'Belarus', code: 'BY' },
      { text: 'Belgium', code: 'BE' },
      { text: 'Belize', code: 'BZ' },
      { text: 'Benin', code: 'BJ' },
      { text: 'Bermuda', code: 'BM' },
      { text: 'Bhutan', code: 'BT' },
      { text: 'Bolivia', code: 'BO' },
      { text: 'Bonaire', code: 'BQ' },
      { text: 'Bosnia and Herzegovina', code: 'BA' },
      { text: 'Botswana', code: 'BW' },
      { text: 'Bouvet Island', code: 'BV' },
      { text: 'Brazil', code: 'BR' },
      { text: 'British Indian Ocean Territory', code: 'IO' },
      { text: 'Brunei Darussalam', code: 'BN' },
      { text: 'Bulgaria', code: 'BG' },
      { text: 'Burkina Faso', code: 'BF' },
      { text: 'Burundi', code: 'BI' },
      { text: 'Cambodia', code: 'KH' },
      { text: 'Cameroon', code: 'CM' },
      { text: 'Canada', code: 'CA' },
      { text: 'Cape Verde', code: 'CV' },
      { text: 'Cayman Islands', code: 'KY' },
      { text: 'Central African Republic', code: 'CF' },
      { text: 'Chad', code: 'TD' },
      { text: 'Chile', code: 'CL' },
      { text: 'China', code: 'CN' },
      { text: 'Christmas Island', code: 'CX' },
      { text: 'Cocos (Keeling) Islands', code: 'CC' },
      { text: 'Colombia', code: 'CO' },
      { text: 'Comoros', code: 'KM' },
      { text: 'Congo', code: 'CG' },
      { text: 'Democratic Republic of the Congo', code: 'CD' },
      { text: 'Cook Islands', code: 'CK' },
      { text: 'Costa Rica', code: 'CR' },
      { text: 'Croatia', code: 'HR' },
      { text: 'Cuba', code: 'CU' },
      { text: 'Curacao', code: 'CW' },
      { text: 'Cyprus', code: 'CY' },
      { text: 'Czech Republic', code: 'CZ' },
      { text: 'Cote d\'Ivoire', code: 'CI' },
      { text: 'Denmark', code: 'DK' },
      { text: 'Djibouti', code: 'DJ' },
      { text: 'Dominica', code: 'DM' },
      { text: 'Dominican Republic', code: 'DO' },
      { text: 'Ecuador', code: 'EC' },
      { text: 'Egypt', code: 'EG' },
      { text: 'El Salvador', code: 'SV' },
      { text: 'Equatorial Guinea', code: 'GQ' },
      { text: 'Eritrea', code: 'ER' },
      { text: 'Estonia', code: 'EE' },
      { text: 'Ethiopia', code: 'ET' },
      { text: 'Falkland Islands (Malvinas)', code: 'FK' },
      { text: 'Faroe Islands', code: 'FO' },
      { text: 'Fiji', code: 'FJ' },
      { text: 'Finland', code: 'FI' },
      { text: 'France', code: 'FR' },
      { text: 'French Guiana', code: 'GF' },
      { text: 'French Polynesia', code: 'PF' },
      { text: 'French Southern Territories', code: 'TF' },
      { text: 'Gabon', code: 'GA' },
      { text: 'Gambia', code: 'GM' },
      { text: 'Georgia', code: 'GE' },
      { text: 'Germany', code: 'DE' },
      { text: 'Ghana', code: 'GH' },
      { text: 'Gibraltar', code: 'GI' },
      { text: 'Greece', code: 'GR' },
      { text: 'Greenland', code: 'GL' },
      { text: 'Grenada', code: 'GD' },
      { text: 'Guadeloupe', code: 'GP' },
      { text: 'Guam', code: 'GU' },
      { text: 'Guatemala', code: 'GT' },
      { text: 'Guernsey', code: 'GG' },
      { text: 'Guinea', code: 'GN' },
      { text: 'Guinea-Bissau', code: 'GW' },
      { text: 'Guyana', code: 'GY' },
      { text: 'Haiti', code: 'HT' },
      { text: 'Heard Island and McDonald Islands', code: 'HM' },
      { text: 'Holy See (Vatican City State)', code: 'VA' },
      { text: 'Honduras', code: 'HN' },
      { text: 'Hong Kong', code: 'HK' },
      { text: 'Hungary', code: 'HU' },
      { text: 'Iceland', code: 'IS' },
      { text: 'India', code: 'IN' },
      { text: 'Indonesia', code: 'ID' },
      { text: 'Iran, Islamic Republic of', code: 'IR' },
      { text: 'Iraq', code: 'IQ' },
      { text: 'Ireland', code: 'IE' },
      { text: 'Isle of Man', code: 'IM' },
      { text: 'Israel', code: 'IL' },
      { text: 'Italy', code: 'IT' },
      { text: 'Jamaica', code: 'JM' },
      { text: 'Japan', code: 'JP' },
      { text: 'Jersey', code: 'JE' },
      { text: 'Jordan', code: 'JO' },
      { text: 'Kazakhstan', code: 'KZ' },
      { text: 'Kenya', code: 'KE' },
      { text: 'Kiribati', code: 'KI' },
      { text: 'Korea, Democratic People\'s Republic of', code: 'KP' },
      { text: 'Korea, Republic of', code: 'KR' },
      { text: 'Kuwait', code: 'KW' },
      { text: 'Kyrgyzstan', code: 'KG' },
      { text: 'Lao People\'s Democratic Republic', code: 'LA' },
      { text: 'Latvia', code: 'LV' },
      { text: 'Lebanon', code: 'LB' },
      { text: 'Lesotho', code: 'LS' },
      { text: 'Liberia', code: 'LR' },
      { text: 'Libya', code: 'LY' },
      { text: 'Liechtenstein', code: 'LI' },
      { text: 'Lithuania', code: 'LT' },
      { text: 'Luxembourg', code: 'LU' },
      { text: 'Macao', code: 'MO' },
      { text: 'Macedonia, the Former Yugoslav Republic of', code: 'MK' },
      { text: 'Madagascar', code: 'MG' },
      { text: 'Malawi', code: 'MW' },
      { text: 'Malaysia', code: 'MY' },
      { text: 'Maldives', code: 'MV' },
      { text: 'Mali', code: 'ML' },
      { text: 'Malta', code: 'MT' },
      { text: 'Marshall Islands', code: 'MH' },
      { text: 'Martinique', code: 'MQ' },
      { text: 'Mauritania', code: 'MR' },
      { text: 'Mauritius', code: 'MU' },
      { text: 'Mayotte', code: 'YT' },
      { text: 'Mexico', code: 'MX' },
      { text: 'Micronesia, Federated States of', code: 'FM' },
      { text: 'Moldova, Republic of', code: 'MD' },
      { text: 'Monaco', code: 'MC' },
      { text: 'Mongolia', code: 'MN' },
      { text: 'Montenegro', code: 'ME' },
      { text: 'Montserrat', code: 'MS' },
      { text: 'Morocco', code: 'MA' },
      { text: 'Mozambique', code: 'MZ' },
      { text: 'Myanmar', code: 'MM' },
      { text: 'Namibia', code: 'NA' },
      { text: 'Nauru', code: 'NR' },
      { text: 'Nepal', code: 'NP' },
      { text: 'Netherlands', code: 'NL' },
      { text: 'New Caledonia', code: 'NC' },
      { text: 'New Zealand', code: 'NZ' },
      { text: 'Nicaragua', code: 'NI' },
      { text: 'Niger', code: 'NE' },
      { text: 'Nigeria', code: 'NG' },
      { text: 'Niue', code: 'NU' },
      { text: 'Norfolk Island', code: 'NF' },
      { text: 'Northern Mariana Islands', code: 'MP' },
      { text: 'Norway', code: 'NO' },
      { text: 'Oman', code: 'OM' },
      { text: 'Pakistan', code: 'PK' },
      { text: 'Palau', code: 'PW' },
      { text: 'Palestine, State of', code: 'PS' },
      { text: 'Panama', code: 'PA' },
      { text: 'Papua New Guinea', code: 'PG' },
      { text: 'Paraguay', code: 'PY' },
      { text: 'Peru', code: 'PE' },
      { text: 'Philippines', code: 'PH' },
      { text: 'Pitcairn', code: 'PN' },
      { text: 'Poland', code: 'PL' },
      { text: 'Portugal', code: 'PT' },
      { text: 'Puerto Rico', code: 'PR' },
      { text: 'Qatar', code: 'QA' },
      { text: 'Romania', code: 'RO' },
      { text: 'Russian Federation', code: 'RU' },
      { text: 'Rwanda', code: 'RW' },
      { text: 'Reunion', code: 'RE' },
      { text: 'Saint Barthelemy', code: 'BL' },
      { text: 'Saint Helena', code: 'SH' },
      { text: 'Saint Kitts and Nevis', code: 'KN' },
      { text: 'Saint Lucia', code: 'LC' },
      { text: 'Saint Martin (French part)', code: 'MF' },
      { text: 'Saint Pierre and Miquelon', code: 'PM' },
      { text: 'Saint Vincent and the Grenadines', code: 'VC' },
      { text: 'Samoa', code: 'WS' },
      { text: 'San Marino', code: 'SM' },
      { text: 'Sao Tome and Principe', code: 'ST' },
      { text: 'Saudi Arabia', code: 'SA' },
      { text: 'Senegal', code: 'SN' },
      { text: 'Serbia', code: 'RS' },
      { text: 'Seychelles', code: 'SC' },
      { text: 'Sierra Leone', code: 'SL' },
      { text: 'Singapore', code: 'SG' },
      { text: 'Sint Maarten (Dutch part)', code: 'SX' },
      { text: 'Slovakia', code: 'SK' },
      { text: 'Slovenia', code: 'SI' },
      { text: 'Solomon Islands', code: 'SB' },
      { text: 'Somalia', code: 'SO' },
      { text: 'South Africa', code: 'ZA' },
      { text: 'South Georgia and the South Sandwich Islands', code: 'GS' },
      { text: 'South Sudan', code: 'SS' },
      { text: 'Spain', code: 'ES' },
      { text: 'Sri Lanka', code: 'LK' },
      { text: 'Sudan', code: 'SD' },
      { text: 'Suriname', code: 'SR' },
      { text: 'Svalbard and Jan Mayen', code: 'SJ' },
      { text: 'Swaziland', code: 'SZ' },
      { text: 'Sweden', code: 'SE' },
      { text: 'Switzerland', code: 'CH' },
      { text: 'Syrian Arab Republic', code: 'SY' },
      { text: 'Taiwan', code: 'TW' },
      { text: 'Tajikistan', code: 'TJ' },
      { text: 'United Republic of Tanzania', code: 'TZ' },
      { text: 'Thailand', code: 'TH' },
      { text: 'Timor-Leste', code: 'TL' },
      { text: 'Togo', code: 'TG' },
      { text: 'Tokelau', code: 'TK' },
      { text: 'Tonga', code: 'TO' },
      { text: 'Trinidad and Tobago', code: 'TT' },
      { text: 'Tunisia', code: 'TN' },
      { text: 'Turkey', code: 'TR' },
      { text: 'Turkmenistan', code: 'TM' },
      { text: 'Turks and Caicos Islands', code: 'TC' },
      { text: 'Tuvalu', code: 'TV' },
      { text: 'Uganda', code: 'UG' },
      { text: 'Ukraine', code: 'UA' },
      { text: 'United Arab Emirates', code: 'AE' },
      { text: 'United Kingdom', code: 'GB' },
      { text: 'United States', code: 'US' },
      { text: 'United States Minor Outlying Islands', code: 'UM' },
      { text: 'Uruguay', code: 'UY' },
      { text: 'Uzbekistan', code: 'UZ' },
      { text: 'Vanuatu', code: 'VU' },
      { text: 'Venezuela', code: 'VE' },
      { text: 'Viet Nam', code: 'VN' },
      { text: 'British Virgin Islands', code: 'VG' },
      { text: 'US Virgin Islands', code: 'VI' },
      { text: 'Wallis and Futuna', code: 'WF' },
      { text: 'Western Sahara', code: 'EH' },
      { text: 'Yemen', code: 'YE' },
      { text: 'Zambia', code: 'ZM' },
      { text: 'Zimbabwe', code: 'ZW' }];
  }
  getLanguageCodes() {
    return [{ text: 'Abkhazian', code: 'ab' },
    { text: 'Afar', code: 'aa' },
    { text: 'Afrikaans', code: 'af' },
    { text: 'Albanian', code: 'sq' },
    { text: 'Amharic', code: 'am' },
    { text: 'Arabic', code: 'ar' },
    { text: 'Aragonese', code: 'an' },
    { text: 'Armenian', code: 'hy' },
    { text: 'Assamese', code: 'as' },
    { text: 'Avestan', code: 'ae' },
    { text: 'Aymara', code: 'ay' },
    { text: 'Azerbaijani', code: 'az' },
    { text: 'Bashkir', code: 'ba' },
    { text: 'Basque', code: 'eu' },
    { text: 'Belarusian', code: 'be' },
    { text: 'Bengali', code: 'bn' },
    { text: 'Bihari', code: 'bh' },
    { text: 'Bislama', code: 'bi' },
    { text: 'Bosnian', code: 'bs' },
    { text: 'Breton', code: 'br' },
    { text: 'Bulgarian', code: 'bg' },
    { text: 'Burmese', code: 'my' },
    { text: 'Catalan', code: 'ca' },
    { text: 'Chamorro', code: 'ch' },
    { text: 'Chechen', code: 'ce' },
    { text: 'Chinese', code: 'zh' },
    { text: 'Church Slavic; Slavonic; Old Bulgarian', code: 'cu' },
    { text: 'Chuvash', code: 'cv' },
    { text: 'Cornish', code: 'kw' },
    { text: 'Corsican', code: 'co' },
    { text: 'Croatian', code: 'hr' },
    { text: 'Czech', code: 'cs' },
    { text: 'Danish', code: 'da' },
    { text: 'Divehi; Dhivehi; Maldivian', code: 'dv' },
    { text: 'Dutch', code: 'nl' },
    { text: 'Dzongkha', code: 'dz' },
    { text: 'English', code: 'en' },
    { text: 'Esperanto', code: 'eo' },
    { text: 'Estonian', code: 'et' },
    { text: 'Faroese', code: 'fo' },
    { text: 'Fijian', code: 'fj' },
    { text: 'Finnish', code: 'fi' },
    { text: 'French', code: 'fr' },
    { text: 'Gaelic; Scottish Gaelic', code: 'gd' },
    { text: 'Galician', code: 'gl' },
    { text: 'Georgian', code: 'ka' },
    { text: 'German', code: 'de' },
    { text: 'Greek, Modern (1453-)', code: 'el' },
    { text: 'Guarani', code: 'gn' },
    { text: 'Gujarati', code: 'gu' },
    { text: 'Haitian; Haitian Creole', code: 'ht' },
    { text: 'Hausa', code: 'ha' },
    { text: 'Hebrew', code: 'he' },
    { text: 'Herero', code: 'hz' },
    { text: 'Hindi', code: 'hi' },
    { text: 'Hiri Motu', code: 'ho' },
    { text: 'Hungarian', code: 'hu' },
    { text: 'Icelandic', code: 'is' },
    { text: 'Ido', code: 'io' },
    { text: 'Indonesian', code: 'id' },
    { text: 'Interlingua (International Auxiliary Language Association)', code: 'ia' },
    { text: 'Interlingue', code: 'ie' },
    { text: 'Inuktitut', code: 'iu' },
    { text: 'Inupiaq', code: 'ik' },
    { text: 'Irish', code: 'ga' },
    { text: 'Italian', code: 'it' },
    { text: 'Japanese', code: 'ja' },
    { text: 'Javanese', code: 'jv' },
    { text: 'Kalaallisut', code: 'kl' },
    { text: 'Kannada', code: 'kn' },
    { text: 'Kashmiri', code: 'ks' },
    { text: 'Kazakh', code: 'kk' },
    { text: 'Khmer', code: 'km' },
    { text: 'Kikuyu; Gikuyu', code: 'ki' },
    { text: 'Kinyarwanda', code: 'rw' },
    { text: 'Kirghiz', code: 'ky' },
    { text: 'Komi', code: 'kv' },
    { text: 'Korean', code: 'ko' },
    { text: 'Kuanyama; Kwanyama', code: 'kj' },
    { text: 'Kurdish', code: 'ku' },
    { text: 'Lao', code: 'lo' },
    { text: 'Latin', code: 'la' },
    { text: 'Latvian', code: 'lv' },
    { text: 'Limburgan; Limburger; Limburgish', code: 'li' },
    { text: 'Lingala', code: 'ln' },
    { text: 'Lithuanian', code: 'lt' },
    { text: 'Luxembourgish; Letzeburgesch', code: 'lb' },
    { text: 'Macedonian', code: 'mk' },
    { text: 'Malagasy', code: 'mg' },
    { text: 'Malay', code: 'ms' },
    { text: 'Malayalam', code: 'ml' },
    { text: 'Maltese', code: 'mt' },
    { text: 'Manx', code: 'gv' },
    { text: 'Maori', code: 'mi' },
    { text: 'Marathi', code: 'mr' },
    { text: 'Marshallese', code: 'mh' },
    { text: 'Moldavian', code: 'mo' },
    { text: 'Mongolian', code: 'mn' },
    { text: 'Nauru', code: 'na' },
    { text: 'Navaho, Navajo', code: 'nv' },
    { text: 'Ndebele, North', code: 'nd' },
    { text: 'Ndebele, South', code: 'nr' },
    { text: 'Ndonga', code: 'ng' },
    { text: 'Nepali', code: 'ne' },
    { text: 'Northern Sami', code: 'se' },
    { text: 'Norwegian', code: 'no' },
    { text: 'Norwegian Bokmal', code: 'nb' },
    { text: 'Norwegian Nynorsk', code: 'nn' },
    { text: 'Nyanja; Chichewa; Chewa', code: 'ny' },
    { text: 'Occitan (post 1500); Provencal', code: 'oc' },
    { text: 'Oriya', code: 'or' },
    { text: 'Oromo', code: 'om' },
    { text: 'Ossetian; Ossetic', code: 'os' },
    { text: 'Pali', code: 'pi' },
    { text: 'Panjabi', code: 'pa' },
    { text: 'Persian', code: 'fa' },
    { text: 'Polish', code: 'pl' },
    { text: 'Portuguese', code: 'pt' },
    { text: 'Pushto', code: 'ps' },
    { text: 'Quechua', code: 'qu' },
    { text: 'Raeto-Romance', code: 'rm' },
    { text: 'Romanian', code: 'ro' },
    { text: 'Rundi', code: 'rn' },
    { text: 'Russian', code: 'ru' },
    { text: 'Samoan', code: 'sm' },
    { text: 'Sango', code: 'sg' },
    { text: 'Sanskrit', code: 'sa' },
    { text: 'Sardinian', code: 'sc' },
    { text: 'Serbian', code: 'sr' },
    { text: 'Shona', code: 'sn' },
    { text: 'Sichuan Yi', code: 'ii' },
    { text: 'Sindhi', code: 'sd' },
    { text: 'Sinhala; Sinhalese', code: 'si' },
    { text: 'Slovak', code: 'sk' },
    { text: 'Slovenian', code: 'sl' },
    { text: 'Somali', code: 'so' },
    { text: 'Sotho, Southern', code: 'st' },
    { text: 'Spanish; Castilian', code: 'es' },
    { text: 'Sundanese', code: 'su' },
    { text: 'Swahili', code: 'sw' },
    { text: 'Swati', code: 'ss' },
    { text: 'Swedish', code: 'sv' },
    { text: 'Tagalog', code: 'tl' },
    { text: 'Tahitian', code: 'ty' },
    { text: 'Tajik', code: 'tg' },
    { text: 'Tamil', code: 'ta' },
    { text: 'Tatar', code: 'tt' },
    { text: 'Telugu', code: 'te' },
    { text: 'Thai', code: 'th' },
    { text: 'Tibetan', code: 'bo' },
    { text: 'Tigrinya', code: 'ti' },
    { text: 'Tonga (Tonga Islands)', code: 'to' },
    { text: 'Tsonga', code: 'ts' },
    { text: 'Tswana', code: 'tn' },
    { text: 'Turkish', code: 'tr' },
    { text: 'Turkmen', code: 'tk' },
    { text: 'Twi', code: 'tw' },
    { text: 'Uighur', code: 'ug' },
    { text: 'Ukrainian', code: 'uk' },
    { text: 'Urdu', code: 'ur' },
    { text: 'Uzbek', code: 'uz' },
    { text: 'Vietnamese', code: 'vi' },
    { text: 'Volapuk', code: 'vo' },
    { text: 'Walloon', code: 'wa' },
    { text: 'Welsh', code: 'cy' },
    { text: 'Western Frisian', code: 'fy' },
    { text: 'Wolof', code: 'wo' },
    { text: 'Xhosa', code: 'xh' },
    { text: 'Yiddish', code: 'yi' },
    { text: 'Yoruba', code: 'yo' },
    { text: 'Zhuang; Chuang', code: 'za' },
    { text: 'Zulu', code: 'zu	  ' }]
  }
}

