import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { UploadModule } from './upload/upload.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContentListComponent } from './content-list/content-list.component';
import { NgMaterialsModule } from './ng-materials/ng-materials.module';
import { ContentDetailComponent } from './content-detail/content-detail.component';
import { LoginComponent } from './login/login.component';
import { DeleteConfirmComponent } from './dialogs/delete-confirm/delete-confirm.component';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatToolbarModule, MatIconModule, MatSidenavModule, MatListModule, MatButtonModule } from '@angular/material';

import { UrlGenComponent } from './url-gen/url-gen.component';
import { CoursecompleteComponent } from './coursecomplete/coursecomplete.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PushConfirmComponent } from './dialogs/push-confirm/push-confirm.component';
import { ActivateConfirmComponent } from './dialogs/activate-confirm/activate-confirm.component';
import { CourseInventoryComponent } from './course-inventory/course-inventory.component';
import { AppBaseComponent } from './app-base/app-base.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { QaLinksComponent } from './qa-links/qa-links.component';
@NgModule({
  declarations: [
    AppComponent,
    ContentListComponent,
    ContentDetailComponent,
    LoginComponent,
    ResetPasswordComponent,
    DeleteConfirmComponent,
    UrlGenComponent,
    CoursecompleteComponent,
    PushConfirmComponent,
    ActivateConfirmComponent,
    CourseInventoryComponent,
    AppBaseComponent,
    NavBarComponent,
    QaLinksComponent

  ],
  entryComponents: [DeleteConfirmComponent, PushConfirmComponent, ActivateConfirmComponent],//dynamically added components!
  imports: [
    BrowserModule,
    AppRoutingModule,
    UploadModule,
    NgMaterialsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    MatToolbarModule, MatIconModule, MatSidenavModule, MatListModule, MatButtonModule 
  ],
  providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } }],
  bootstrap: [AppComponent]
})
export class AppModule { }
