import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  constructor(iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer, 
    private authService: AuthService) { 
    iconRegistry.addSvgIcon('logout', sanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/baseline-exit_to_app-24px.svg'));
  }

  ngOnInit() {
  }
  logOut() {
    this.authService.logOut();
  }
}
