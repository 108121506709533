
export const environment = {
  production: true,
  awsconfig: {
    "aws_project_region": "us-west-2",
    "aws_content_delivery_bucket": "lms-contents-bucket-host-production",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d1uhxqcg7nx27r.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-west-2:d0784d9a-48d1-4d56-b985-ef7fa18bdbd7",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_SAuihmCNg",
    "aws_user_pools_web_client_id": "2urtp4q0b0165ornqgn3s55ha8",
    "oauth": {}
  },
  LMS_SERVER_HOST: 'https://lms-production.edriving.com',
  LMS_SERVER_PRIVATE_HOST: 'https://w96bflzbgk.execute-api.us-west-2.amazonaws.com/prod',
  LMS_SERVER_PRIVATE_HOST_PRODUCTION: 'https://w96bflzbgk.execute-api.us-west-2.amazonaws.com/prod',
  TEST_USER: '515597122',
  MAX_FILE_SIZE: 100000000,
  APP_BASSE: "/lmsadmin/"
}
//2Vs:8:^FM;(k6:XN