import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-base',
  templateUrl: './app-base.component.html',
  styleUrls: ['./app-base.component.css']
})
export class AppBaseComponent implements OnInit {
 appBase:string;
  constructor() {
    this.appBase=environment.APP_BASSE;
   }

  ngOnInit() {
  }

}
