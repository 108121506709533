import { Component, HostListener, OnInit, ViewChild } from '@angular/core';

import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CourseData } from '../models/course-data';
import { ContentDetailComponent } from '../content-detail/content-detail.component';
import { ContentService } from '../service/content.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../service/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmComponent } from '../dialogs/delete-confirm/delete-confirm.component';
import { PushConfirmComponent } from '../dialogs/push-confirm/push-confirm.component';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { ActivateConfirmComponent } from '../dialogs/activate-confirm/activate-confirm.component';




let moment = require('moment');

@Component({
  selector: 'content-list',
  templateUrl: './content-list.component.html',
  styleUrls: ['./content-list.component.css']
})
export class ContentListComponent implements OnInit {
  displayedColumns: string[] = [ 'course_name', 'content_title', 'activity_id', 'language', 'locale', 'author', 'updated_at', 'pushed_at', 'content_id'];
  dataSourceCourse: MatTableDataSource<CourseData>;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(ContentDetailComponent) current_detail: ContentDetailComponent;
  editMode = false;
  showEdit= false;
  isProd= false;
  contents: any[] = [];
  courses: any[] = [];
  categories: any[] = [];
  categoryNames: string[];
  courseNames: string[];
  currentCourse: CourseData;
  activity_id: string;
  content_key: string;
  course_name: string;
  refreshReportTimer: any;
  remaining:number;
  constructor(iconRegistry: MatIconRegistry,
     sanitizer: DomSanitizer, private contentService: ContentService, 
     private authService: AuthService, public dialog: MatDialog, private router: Router) {
    iconRegistry.addSvgIcon('edit', sanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/baseline-edit-24px.svg'));
    iconRegistry.addSvgIcon('delete', sanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/baseline-delete_forever-24px.svg'));
    iconRegistry.addSvgIcon('play', sanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/baseline-play_circle_outline-24px.svg'));
    iconRegistry.addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/baseline-add_circle_outline-24px.svg'));
    iconRegistry.addSvgIcon('save', sanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/baseline-save_alt-24px.svg'));
    iconRegistry.addSvgIcon('cancel', sanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/baseline-cancel-24px.svg'));
    iconRegistry.addSvgIcon('logout', sanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/baseline-exit_to_app-24px.svg'));
    iconRegistry.addSvgIcon('link', sanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/baseline-link-24px.svg'));
    iconRegistry.addSvgIcon('copy', sanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/file_copy-24px.svg'));
    this.dataSourceCourse = new MatTableDataSource(this.contents);
    if (!environment.production) {
    this.showEdit = true;
    } else {
      this.isProd = true;
      const user = sessionStorage.getItem('user');
      if (!user) { this.router.navigate(['login']); }
      const userObj: any = JSON.parse(user);
      userObj.attributes['custom:isTemp'] === 'Y' ? this.showEdit = true : this.showEdit = false;
    }
  }
 

  saveCategory(current_detail, categories, contentService) {
    return new Promise(function(resolve, reject) {
      if (current_detail.contentForm.value.category_name) {
        const exist = categories.filter(c => c.name == current_detail.contentForm.value.category_name);
        if (exist.length == 0) {
          contentService.createCourseCategory({ name: current_detail.contentForm.value.category_name }).subscribe(e => {
            if (e instanceof HttpResponse) {
              current_detail.contentForm.value.category_id = e.body.category_id;
              return resolve();
            } else if (e instanceof HttpErrorResponse) {
              console.log(e);
              return reject();
            } else {
              console.log(e);

            }
          });
        } else {
          current_detail.contentForm.value.category_id = exist[0].category_id;
          return resolve();
        }
      } else {
        return reject();
      }
    });
  }
  saveCourse(current_detail, courses, contentService) {

    return new Promise((resolve, reject) => {
        if (current_detail.contentForm.value.course_name) {
          const exist = courses.filter(c => c.name === current_detail.contentForm.value.course_name);
          if (exist.length === 0) {
            try {
              contentService.createCourse({
                name: current_detail.contentForm.value.course_name,
                category_id: current_detail.contentForm.value.category_id,
                course_version: current_detail.contentForm.value.course_version
              }).subscribe(e => {
                if (e instanceof HttpResponse) {
                  current_detail.contentForm.value.course_id = e.body.course_id;
                  return resolve();
                } else if (e instanceof HttpErrorResponse) {
                  console.log(e);
                  return reject();
                } else {
                  console.log(e);

                }
              });
            } catch (ex) {
              console.log(ex);
              return reject();
            }
          } else {
            console.log('sending update course');
            try {
              let course_json = {
                name: current_detail.contentForm.value.course_name,
                category_id: current_detail.contentForm.value.category_id, course_version: current_detail.contentForm.value.version
              };
              contentService.updateCourse(course_json, exist[0].course_id).subscribe(e => {
                if (e instanceof HttpResponse) {
                  console.log('e.body:', e.body);
                  current_detail.contentForm.value.course_id = exist[0].course_id;
                  return resolve();
                } else if (e instanceof HttpErrorResponse) {
                  console.log(e);
                  return reject();
                } else {
                  console.log(e);

                }
              });
            } catch (ex) {
              console.log(ex);
              return reject();
            }
          }
        }
      });
  }
  async saveContent() {

    if (!this.current_detail.contentForm.valid || this.current_detail.hasError) { return; }
    try {
      if (!this.current_detail.contentForm.value.activity_id) { this.current_detail.contentForm.value.activity_id = this.activity_id; }
      if (!this.current_detail.contentForm.value.course_name) { this.current_detail.contentForm.value.course_name = this.course_name; }
      if (!this.current_detail.contentForm.value.content_key) { this.current_detail.contentForm.value.course_name = this.content_key; }
      await this.saveCategory(this.current_detail, this.categories, this.contentService);
      await this.saveCourse(this.current_detail, this.courses, this.contentService);

    } catch (ex) {
      console.log(ex);

    }

    if (this.current_detail.contentForm.value.content_id > 0) {
      this.contentService.updateCourseContent(this.current_detail.contentForm.value).toPromise().then(e => {
        this.reloadAllData();
        this.editMode = false;
      }, r => console.log(r));
    } else {

      this.contentService.createCourseContent(this.current_detail.contentForm.value).toPromise().then(e => {
        this.reloadAllData();
        this.editMode = false;
      }, r => console.log(r));
    }

  }
  reloadContets() {

    this.contentService.getAllCourseContents().subscribe((event) => {
      if (event instanceof HttpResponse) {
        this.contents = event.body.data;
        for (const content of this.contents) {
          if (this.contentService.getCountryCodeByValue(content.country)) {
            content.country = this.contentService.getCountryCodeByValue(content.country).text;
          }
          if (this.contentService.getLanguageCodeByValue(content.language)) {
            content.language = this.contentService.getLanguageCodeByValue(content.language).text;
          }
        }
        this.dataSourceCourse = new MatTableDataSource(this.contents);
        this.dataSourceCourse.paginator = this.paginator;
        this.dataSourceCourse.sort = this.sort;
        this.paginator.pageIndex = 0;
      } else if (event instanceof HttpErrorResponse) {
        console.log(event);
      }
    });


  }

  async reloadAllData() {
    this.contentService.getAllCourses().subscribe((event) => {
      if (event instanceof HttpResponse) {
        this.courses = event.body.data;
        this.courseNames = [];
        for (const course of this.courses) {
          this.courseNames.push(course.name);
        }
      } else if (event instanceof HttpErrorResponse) {
        console.log(event);
      }
    });
    this.contentService.getAllCourseCategores().subscribe((event) => {
      if (event instanceof HttpResponse) {
        this.categories = event.body.data;
        this.categoryNames = [];
        for (const cat of this.categories) {
          this.categoryNames.push(cat.name);
        }
      } else if (event instanceof HttpErrorResponse) {
        console.log(event);
      }
    });
    this.reloadContets();
  }
  onContentInit(e: any) {
    this.activity_id = e.data.activity_id;
    this.course_name = e.data.name;
    this.content_key = e.data.content_key;
  }
  ngOnInit() {
    this.authService.setTimeout();
    this.reloadAllData();
    this.currentCourse = new CourseData('', '', 0, '', '', '', '', '', 0, '', '', 0, 0, '', '', '', '');
  }
  edit(content_id) {
    this.editMode = true;
    this.currentCourse = this.contents.find(c => c.content_id == content_id) as CourseData;
    console.log('selected content', this.currentCourse);

  }
  addNew() {
    this.editMode = true;
    this.currentCourse = new CourseData('', '', 0, '', '', '', '', '', 0, '', '', 0, 0, '', '', '', '');
  }
  openConfirmDelete(content_id: number): void {
    const dialogRef = this.dialog.open(DeleteConfirmComponent, {
      width: '250px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') { this.delete(content_id); }

    });
  }

  openConfirmPush(content_key: string): void {
    const dialogRef = this.dialog.open(PushConfirmComponent, {
      width: '250px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') { this.push(content_key); }

    });
  }

  openConfirmActivate(activity_id: string): void {
    const dialogRef = this.dialog.open(ActivateConfirmComponent, {
      width: '250px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') { this.activate(activity_id); }

    });
  }


  delete(content_id) {
    this.contentService.deleteCourseContent(content_id).toPromise().then(
      () => {

        this.reloadAllData();
      },
      (err) => {
        console.log(err);
      }

    );
  }
  activate(activity_id) {

    this.contentService.activateCourseContent(activity_id).toPromise().then(
      () => {

        this.reloadAllData();
      },
      (err) => {
        console.log(err);
      }

    );
  }
  push(content_key) {

    this.contentService.copyCourseContentGet(content_key).toPromise().then(
      (event) => {
        if (event instanceof HttpResponse) {
          console.log(event.body);
          if (event.body) {
            const payload = event.body;
            delete payload.category.category_id;
            delete payload.content.content_id;
            delete payload.course.course_id;
            this.contentService.copyCourseContentPost(payload).toPromise().then(
              (e2) => {
                if (e2 instanceof HttpResponse) {
                  this.contentService.copyCoursecomplete(content_key).toPromise().then((e3) => {

                    if (e3 instanceof HttpResponse) {
                     this.reloadAllData();
                    } else if (e3 instanceof HttpErrorResponse) {
                      console.log(e3);
                    }
                  });
                } else if (e2 instanceof HttpErrorResponse) {
                  console.log(e2);
                }

              });

          }

        } else if (event instanceof HttpErrorResponse) {
          console.log(event);
        }
      }
    );

  }
  preview(content_id, activity_id) {
    this.contentService.getCourseContentURL(content_id).toPromise().then(
      (event) => {
        if (event instanceof HttpResponse) {
          const url = event.body.url;
          window.open(url, '_blank');

          console.log(url);
          // report page
          let x = window.open('lms_preview_reports', 'popup', 'width=600,height=800,scrollbars=yes,resizable=yes');
          x.document.open();
          x.document.write('<html><body>Initializing...</body></html>');
          x.document.close();
          let cs = this.contentService;
          let startTime = Date.now();
          let refreshReport = function() {
            cs.getLmsProgress(activity_id, startTime).toPromise().then(
              (event) => {
                if (event instanceof HttpResponse) {
                  let lmsProgressJson = event.body;

                  cs.getLrsReport(activity_id, startTime).toPromise().then(
                    (event) => {
                      if (event instanceof HttpResponse) {
                        let statementsJson = event.body;
                        console.log(statementsJson);

                        cs.getVrmReport(activity_id, startTime).toPromise().then(
                          (event) => {
                            if (event instanceof HttpResponse) {
                              let vrmReportJson = event.body;
                              console.log(vrmReportJson);

                              // -----------assemble LMS progress info
                              console.log(lmsProgressJson);
                              var progressHtml = '';
                              if (lmsProgressJson.length == 0) {
                          progressHtml = '<strong style="color:red">No Progress Captured in LMS</strong>';
                        } else { for(var i=0;i<lmsProgressJson.length;i++){
                          var p = lmsProgressJson[i];
                          progressHtml += `${p.registration}<ul>
                          <li>Current Slide Name: ${p.current_slide_name}</li>
                          <li>Last Slide: ${p.last_slide}</li>
                          <li>Course Status: ${p.course_status}</li>
                          <li>is_complete: ${p.is_complete}</li>
                          <li>passed: ${p.passed}</li>
                          <li>variables: ${p.state}</li>
                          </ul>`
                        }
                        }
                        // -----------assemble LRS progress info
                              var statementsHtml = '';
                              if (statementsJson.data.length == 0) {
                          statementsHtml = '<strong style="color:red">No Progress Captured in LRS</strong>';
                        } else { statementsHtml=cs.getUserLrsProgressStr(statementsJson); }
                        // -----------assemble derived progress info
                              var vrmReportHtml = '';
                              if (!vrmReportJson.data) {
                          vrmReportHtml = '<strong style="color:red">No VRM report</strong>';
                        } else {
                          let vrmReportObj = vrmReportJson.data;
                          vrmReportHtml += `<ul>
                          <li>Chapter Progress</li><ul>`;
                          for (let key in vrmReportObj.chapterCompletion) {
                            vrmReportHtml += `<li>${key} - ${vrmReportObj.chapterCompletion[key]}</li>`;
                          }
                          vrmReportHtml += `</ul>
                          <li>Total Attempts: ${vrmReportObj.totalAttempts}</li>
                          <li>Last Attempt Time: ${moment(vrmReportObj.lastAttemptTime).format('LL LTS')}</li>
                          <li>First Course Complete Time: ${moment(vrmReportObj.firstCompletionTime).format('LL LTS')}</li>
                          <li>Last Course Complete Time: ${moment(vrmReportObj.lastCompletionTime).format('LL LTS')}</li>
                          <li>Answers:</li><ul>`;
                          for (let key in vrmReportObj.vrmQuestionResult) {
                            vrmReportHtml += `<li>${key} - ${vrmReportObj.vrmQuestionResult[key]}</li>`;
                          }
                          vrmReportHtml += `</ul></ul>`;


                        }

                        // -----------render data in popup window

                              x.document.open();
                              x.document.write(`<html>

                        <script>
                        var countDownDate = ${new Date().getTime()};

                        // Update the count down every 1 second
                        var x = setInterval(function() {
                          var now = new Date().getTime();
                          var distance = now - countDownDate;

                          // Display the result in the element with id="demo"
                          document.getElementById("count").innerHTML = Math.round(distance/1000);
                        }, 1000);
                        </script>

                        <body style="font-family: monospace !important">
                        <i>
                        How to use progress report tool:
                        <li>Data refresh every <strong>10 seconds</strong>, wait till data refreshed to verify proper course progress is captured.</li>
                        <li>At the end of the slide, verify <strong>Current Slide Name</strong> and <strong>Last Slide</strong> is updated correctly.</li>
                        <li>At the end of the course (or where course is set to complete in storyline), verify <strong>Course Status</strong> is updated to <strong>completed</strong> and <strong>is_complete</strong> is updated to <strong>1</strong></li>
                        </i>
                        <br/>
                         <h2>${activity_id}</h2>
                         Data refreshed <span id=count></span> seconds ago, at ${new Date()} <br/><br/>
                         <h3>LMS progress</h3>
                         ${progressHtml}
                         <h3>LRS progress</h3>
                         ${statementsHtml}
                         <h3>VRM progress</h3>
                         ${vrmReportHtml}
                         </body></html>`);
                              x.document.close();

                      } else if (event instanceof HttpErrorResponse) {
                        console.log(event);
                      }
                    }
                  );
                } else if (event instanceof HttpErrorResponse) {
                  console.log(event);
                }
              }
            );
                } else if (event instanceof HttpErrorResponse) {
                  console.log(event);
                }
              }
            );
          };

          if (this.refreshReportTimer) { clearInterval(this.refreshReportTimer); }
          refreshReport();
          this.refreshReportTimer = setInterval(refreshReport, 10000);

        } else if (event instanceof HttpErrorResponse) {
          console.log(event);
        }
      }
    );
  }
  previewMeta(content_id, activity_id) {
    this.contentService.getCourseContentURL(content_id).toPromise().then(
      (event) => {
        if (event instanceof HttpResponse) {
          const url = event.body.url;
          
          var metaUrl = url.substr(0,url.indexOf("story.html"))+'meta.xml';
          window.open(metaUrl, '_blank');

        } else if (event instanceof HttpErrorResponse) {
          console.log(event);
        }
      }
    );
  }

  applyFilter(filterValue: string) {
    this.dataSourceCourse.filter = filterValue.trim().toLowerCase();
    if (this.dataSourceCourse.paginator) {
      this.dataSourceCourse.paginator.firstPage();
    }
  }

}

