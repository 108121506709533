export class CourseData {
    constructor(public category_name: string,
        public course_name: string,
        public content_id: Number,
        public description: string,
        public version: string,
        public language: string,
        public country: string,
        public author: string,
        public course_duration: Number,
        public created_at: string,
        public authoring_tool: string,
        public course_id: number,
        public category_id: number,
        public title: string,
        public content_key: string,
        public userjs: string,
        public activity_id: string) {

    }

}