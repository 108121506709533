import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-activate-confirm',
  templateUrl: './activate-confirm.component.html',
  styleUrls: ['./activate-confirm.component.css']
})
export class ActivateConfirmComponent  {

  constructor(public dialogRef: MatDialogRef<ActivateConfirmComponent>) { }

  yesClick(): void {
    this.dialogRef.close('yes');
  }
  cancelClick(): void {
    this.dialogRef.close('no');
  }

}


