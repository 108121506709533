import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentListComponent } from "./content-list/content-list.component";
import { CourseInventoryComponent } from "./course-inventory/course-inventory.component";
import { LoginComponent } from "./login/login.component";
import { AuthGuardService as AuthGuard } from './service/auth-guard.service';
import { CoursecompleteComponent } from './coursecomplete/coursecomplete.component'
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { QaLinksComponent } from './qa-links/qa-links.component';
const routes: Routes = [

  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'LMS Login' }
  },
  {
    path: 'contents',
    component: ContentListComponent, canActivate: [AuthGuard],
    data: { title: 'LMS Contents' }
  },
  {
    path: 'qalinks',
    component: QaLinksComponent, canActivate: [AuthGuard],
    data: { title: 'QA Links' }
  },
  
  {
    path: 'courseinventory',
    component: CourseInventoryComponent, canActivate: [AuthGuard],
    data: { title: 'LMS Course Inventory' }
  },
  {
    path: '2870d8e87aa7413a9441aab26d8ca6e5',
    component: CoursecompleteComponent,
    data: { title: 'complete' }
  },

  {
    path: 'passwordreset/:hascode',
    component: ResetPasswordComponent,
    data: { title: 'Reset Password' }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
