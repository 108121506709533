import { Component, EventEmitter, Output, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DialogComponent } from './dialog/dialog.component';


@Component({
  selector: 'uploader',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent {
  @Output("close") close: EventEmitter<any> = new EventEmitter();
  @Input() key: string;
  constructor(public dialog: MatDialog) {

  }
  public async openUploadDialog() {
    let dialogRef = this.dialog.open(DialogComponent, { width: '50%', height: '100px' });
    dialogRef.componentInstance.key = this.key;
    try {
      let data_from_dialog = await dialogRef.afterClosed().toPromise();// when dialog box closes 
      this.close.emit({ key: data_from_dialog.key, postupload: data_from_dialog.postupload, userjs: data_from_dialog.userjs });
    } catch (ex) {
      console.log(ex);
    }
  }
}