import { Component, OnInit } from '@angular/core';
import { ContentService } from '../service/content.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'url-gen',
  templateUrl: './url-gen.component.html',
  styleUrls: ['./url-gen.component.css']
})
export class UrlGenComponent implements OnInit {
  activity_id: string;
  user_id: string='1234';
  return_url: string;
  period = 24;
  qalink = false;
  qa_token: string;
  token: string;
  hasMessage = false;
  hasError = false;
  constructor(private contentService: ContentService) { }

  ngOnInit() {
  }
  disable() {
    this.hasMessage = false;
    this.hasError = false;
    this.contentService.disableQaLink(this.qa_token).toPromise().then(
      (event) => {
        if (event instanceof HttpResponse) {
          if (this.qalink) {
            this.hasMessage = true;
          }
        } else if (event instanceof HttpErrorResponse) {
          if (this.qalink) {
            this.hasError = true;
          }
        }
      });
    setTimeout(() => {
      this.hasMessage = false;
      this.hasError = false;
    }, 5000);

  }
  parseToken(str) {
    const params = new URLSearchParams(str);
    return params.get('token');
  }
  create() {
    console.log(this.activity_id);
    this.contentService.getCourseContentURLWithActivityId(this.activity_id, this.user_id, this.qalink, this.period).toPromise().then(
      (event) => {
        if (event instanceof HttpResponse) {
          this.qa_token = '';
          if (!this.return_url) { this.return_url = 'https://www.edriving.com'; }
          const url = event.body.url + '&returnurl=' + this.return_url;
          if (this.qalink) {
            this.qa_token = this.parseToken(url);
          }
          window.open(url, '_blank');
        } else if (event instanceof HttpErrorResponse) {
          console.log(event);
        }
      });

  }

}
